<template lang="pug">
    .w-full
        .absolute.top-0.left-0.p-2
            a.text-3xl(@click='back()')
                i.jam.jam-chevron-left

        .w-full
            .pt-4.pb-3.text-center.w-full
                h1.font-bold.text-medclub-blue-300.text-sm Detalhes da consulta

        .py-56(v-if='waiting.datas')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.datas')

        .w-full.p-2(v-else)

            .border.p-4

                div
                    b-field(label='Especialista:')
                        b-select(placeholder='Selecione o especialista' v-model='model.cd_especialista' rounded expanded)
                            option(v-for='option in options.especialistas' :value='option.id' :key='option.id') {{ option.nm_especialista }}

                .mt-2
                    b-field(label='Especialidade:')
                        b-select(placeholder='Selecione a especialidade' v-model='model.cd_especialidade' :disabled='! options.especialidades.length' rounded expanded)
                            option(v-for='option in options.especialidades' :value='option.id' :key='option.id') {{ option.nm_especialidade }}

                .mt-2(v-if='dateList.length')
                    b-field(label='Data:')
                        .max-w-xl.mx-auto
                            Calendar.my-4(locale='pt-BR'
                                :is-expanded='true'
                                :first-day-of-week='1'
                                :min-page='calendarConfigs.minPage'
                                :max-page='calendarConfigs.maxPage'
                                :attributes="[{ highlight: 'blue', dates: [ data ] }]"
                                :available-dates='calendarConfigs.availableDates'
                                @dayclick='onDayClick'
                                )

                .mt-2(v-if='waiting.horarios')
                    b-notification.p-8.bg-white(:closable='false')
                        b-loading(:is-full-page='false' v-model='waiting.horarios')

                .mt-2(v-else-if='estabelecimento')
                    div(v-if='estabelecimento.ie_hora_marcada')
                        b-field(label='Selecione o horário:')
                            .pr-1.pt-1.inline-block(v-for='horario, idx in estabelecimento.hr_horario' :key='idx')
                                b-tag.p-4.font-bold.cursor-pointer(type='is-primary' style='background-color:#1aa6a5 !important' @click.native='selectHorario(horario)') {{ horario.hr_agenda }}
                    div(v-else)
                        p.text-xs.text-gray-600 POR ORDEM DE CHEGADA
                        p.font-bold.text-medclub-blue-400
                            b-icon.align-middle(pack='jam' icon='jam-clock')
                            span A PARTIR DE {{ estabelecimento.hr_inicio }}

                        .flex.justify-center.w-full.mt-4
                            b-button.text-sm(type='is-primary' :disabled='! model.cd_especialista || ! model.cd_especialidade' expanded class='md:max-w-xs' @click='selectHorario(estabelecimento.hr_horario[0])') CONTINUAR

                .mt-2(v-else-if='model.cd_especialidade')
                    p.text-sm.text-red-400.text-right.font-bold Esta data não possui horário disponível.
</template>

<script>
    import { Agendamento } from '../../../middleware'
    import Calendar from 'v-calendar/lib/components/calendar.umd'
    import moment from 'moment'; moment.locale('pt-br')
    export default {
        components: { Calendar },
        created () {
            this.options.especialistas = this.cd_agendas.map(a => { return { id: a.cd_especialista, nm_especialista: a.nm_especialista } })
            if (this.options.especialistas.length === 1) this.model.cd_especialista = this.options.especialistas[0].id
        },
        watch: {
            'model.cd_especialista' (id) {
                if (id) {
                    this.$parent.agendamento.especialista = this.options.especialistas.find(e => e.id === id)
                    let find = this.cd_agendas.find(a => a.cd_especialista === id)
                    this.options.especialidades = find.cd_especialidades.map(e => { return { id: e.cd_especialidade, nm_especialidade: e.nm_especialidade } })
                    if (this.options.especialidades.length === 1) this.model.cd_especialidade = this.options.especialidades[0].id
                }
            },
            'model.cd_especialidade' (id) {
                if (id) { this.getDatas() }
                else {
                    this.dateList = []
                    this.data = null
                }
            },
            'data' (data) {
                if (data) this.getHorarios()
                else this.estabelecimento = null
            }
        },
        data () {
            return {
                model: {
                    cd_especialista: null,
                    cd_especialidade: null
                },
                waiting: { datas: false, horarios: false },
                dateList: [],
                data: null,
                estabelecimento: null,
                options: {
                    especialistas: [],
                    especialidades: []
                },
                calendarConfigs: {
                    availableDates: null,
                    minPage: null,
                    maxPage: null
                },
                cd_agendas: this.$parent.agendamento.estabelecimento.cd_estabelecimento.cd_agendas
            }
        },
        methods: {
            onDayClick (ev) {
                if (! ev.isDisabled) {
                    this.data = moment(ev.id, 'YYYY-MM-DD')._d
                }
            },
            back () {
                this.$parent.agendamento.estabelecimento = null
            },
            selectHorario (horario) {

                if (! this.model.cd_especialista) {
                    this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Selecione o especialista' })
                    return 0
                }

                if (! this.model.cd_especialidade) {
                    this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Selecione a especialidade' })
                    return 0
                }

                this.$parent.agendamento.especialidade = this.options.especialidades.find(e => e.id === this.model.cd_especialidade)
                this.$parent.agendamento.horario = horario
                this.$parent.agendamentoCriado = true
            },
            getDatas () {
                
                let params = {
                    cd_especialista: this.model.cd_especialista,
                    cd_especialidade: this.model.cd_especialidade,
                    ie_telemedicina: false,
                    ie_tipo_agenda: 'C',
                    cd_cidade: this.$store.state.cdCidade,
                    cd_estado: this.$store.state.cdEstado,
                }
                if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))


                if (this.$parent.agendamento.estabelecimento) params.cd_estabelecimento = this.$parent.agendamento.estabelecimento.cd_estabelecimento.id

                this.waiting.datas = true
                this.dateList = []
                Agendamento.getEstabelecimentos(params).then(response => {
                    this.waiting.datas = false
                    if (response.status === 200) {
                        this.dateList = []
                        let datas = Object.keys(response.data)
                        datas.forEach(data => {
                            this.dateList.push(this.$root.moment(data, 'DD/MM/YYYY', true)._d)
                        })
                        this.data = this.dateList[0]

                        this.calendarConfigs.availableDates = this.dateList
                        if (this.calendarConfigs.availableDates.length) {
                            let minDate = this.calendarConfigs.availableDates[0]
                            let maxDate = this.calendarConfigs.availableDates[this.calendarConfigs.availableDates.length - 1]
                            this.calendarConfigs.minPage = { month: parseInt(moment(minDate).format('MM')), year: parseInt(moment(minDate).format('YYYY')) }
                            this.calendarConfigs.maxPage = { month: parseInt(moment(maxDate).format('MM')), year: parseInt(moment(maxDate).format('YYYY')) }
                        }
                    }
                })

            },
            getHorarios () {
                
                let params = {
                    cd_especialista: this.model.cd_especialista,
                    cd_especialidade: this.model.cd_especialidade,
                    ie_telemedicina: false,
                    ie_tipo_agenda: 'C',
                    dt_agenda: this.$root.moment(this.data).format('YYYY-MM-DD'),
                    cd_cidade: this.$store.state.cdCidade,
                    cd_estado: this.$store.state.cdEstado,
                }
                
                if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))

                if (this.$parent.agendamento.estabelecimento) params.cd_estabelecimento = this.$parent.agendamento.estabelecimento.cd_estabelecimento.id

                this.waiting.horarios = true
                Agendamento.getEstabelecimentos(params).then(response => {
                    this.waiting.horarios = false
                    if (response.status === 200) {
                        if (this.$parent.agendamento.estabelecimento) this.estabelecimento = Object.values(response.data)[0]
                            .find(e => e.id === this.$parent.agendamento.estabelecimento.cd_estabelecimento.id)
                    }
                })
            }
        }
    }
</script>
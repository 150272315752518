<template lang="pug">
    .w-full
        .absolute.top-0.left-0.p-2
            a.text-3xl(@click='$router.go(-1)')
                i.jam.jam-chevron-left

        .w-full
            .pt-4.pb-3.text-center.w-full
                h1.font-bold.text-medclub-blue-300.text-sm Escolha o check-up

        .py-56(v-if='waiting.checkups')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.checkups')

        .w-full.px-2(v-else-if='list.length')
            .border.my-1.text-sm(v-for='item, idx in list' :key='idx')
                .p-2
                    .flex
                        .w-full(class='md:w-4/6')
                            h1.font-bold {{ item.ds_checkup }}
                            .whitespace-pre-wrap.text-sm(v-html='item.ds_observacao')
                        .hidden.text-right(class='md:block w-2/6')
                            p.text-sm.text-gray-600 TOTAL
                            p.text-2xl.font-bold.text-medclub-blue-400 {{ $root.formatPrice(item.nr_preco) }}
                    
                    .my-2
                        b-collapse(:open='false')
                            template(#trigger='props')
                                .flex.items-bottom.text-medclub-blue-400.font-bold
                                    span {{ props.open ? 'Ocultar ' : 'Ver ' }} procedimentos
                                    b-icon.text-xl(:icon="props.open ? 'menu-up' : 'menu-down'")
                            .notification.p-2
                                .content
                                    div(v-for='procedimento, idx in item.cd_procedimentos' :key='idx')
                                        p {{ idx + 1 }}. {{ procedimento.ds_procedimento }}

                .flex.flex-wrap.items-center.p-2.pb-0.border-t(class='md:hidden')
                    div(class='w-1/2')
                        p.font-bold.text-green-700 TOTAL
                    .text-right(class='w-1/2')
                        p.text-xl.font-bold.text-green-700 {{ $root.formatPrice(item.nr_preco) }}
                .flex.justify-center.p-2.pt-0.w-full.mt-2
                    b-button.text-sm(type='is-primary' expanded class='md:max-w-xs' @click='select(item)') SELECIONAR
</template>

<script>
    import { Agendamento } from '../../../middleware'
    export default {
        created () {
            this.waiting.checkups = true
            Agendamento.getTiposCheckup({ cd_cidade: this.$store.state.cdCidade }).then(response => {
                this.waiting.checkups = false
                if (response.status === 200) this.list = response.data
            })
        },
        data () {
            return {
                list: [],
                waiting: { checkups: false }
            }
        },
        methods: {
            select (item) {
                this.$parent.agendamento.tipo_checkup = item
            }
        }
    }
</script>
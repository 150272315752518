<template lang="pug">
    .agendamento
        .flex.items-center.justify-center.flex-wrap
            .max-w-screen-lg.w-full.relative(style='min-height:75vh')

                div(v-if='! agendamentoCriado')
                    .w-full
                        SelecionarTipoCheckup(v-if='! agendamento.tipo_checkup')
                        SelecionarEstabelecimentoCheckup(v-else-if='! agendamento.estabelecimento')
                        //SelecionarAgenda(v-else)
                div(v-else)
                    SelecionarPaciente
</template>

<script>
    import SelecionarTipoCheckup from './Etapas/TipoCheckup'
    import SelecionarEstabelecimentoCheckup from './Etapas/EstabelecimentoCheckup'
    import SelecionarAgenda from './Etapas/AgendaCheckup'
    import SelecionarPaciente from './Etapas/Paciente'
    export default {
        components: {
            SelecionarTipoCheckup,
            SelecionarEstabelecimentoCheckup,
            SelecionarAgenda,
            SelecionarPaciente
        },
        data () {
            return {
                agendamento: {
                    ie_telemedicina: false,
                    ie_tipo_agenda: 'CK',
                    tipo_checkup: null,
                    estabelecimento: null,
                    especialista: null,
                    especialidade: null,
                    horario: null,
                    paciente: null
                },
                agendamentoCriado: false
            }
        }
    }
</script>